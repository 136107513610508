<template>
  <div 
    v-if="isLoaded()"
    class="
      duck-form 
      add--project--form
    "
  >
    
    <form
      @submit.prevent="saveForm"
      autocomplete="off"
    >
      <fieldset 
        class="
          form-block
          add--project--block
        "
      >
        <div 
          class="form-container"
        >

          <SmallTitle 
            title="ข้อมูลโครงการ"
            class="bottom-line"
          />
          <div 
            class="input"
            :class="{ error: $v.form.name.$error }"
          >
            <Label 
              text="ชื่อโครงการ" 
              :required=true
            />
            <input 
              type="text"
              v-model="form.name"
            >
          </div>
          
          <div 
            class="input"
            :class="{ error: $v.form.cat.$error }"
          >
            <Label 
              text="ประเภทงาน" 
              :required=true
            />
            <ProjectTypeDropdown 
              v-model="form.cat" 
            />
          </div>
          <!-- end: Project type -->
          
          <div 
            class="input"
            :class="{ error: $v.form.building_type.$error }"
          >
            <Label 
              text="ประเภทสิ่งก่อสร้าง"
              :required=true
            />
            <BuildingTypeDropdown 
              v-model="form.building_type" 
            />

            <!-- 
            BuildingTypeDetail display when 
            user choose 'Other' option (value=90)
            -->
            <input 
              v-model="form.building_detail" 
              v-if="
                form.building_type==90
              "
              type="text" 
              class="input-newline"
              placeholder="กรุณาระบุ"
            >
          </div>
          <!-- end: Building type -->
          
          <div class="input">
            <Label 
              text="รายละเอียดโครงการ" 
            />

            <textarea 
              v-model="form.building_detail" 
              rows="3"
            ></textarea>
          </div>
          <!-- end: Project Detail -->


          <SmallTitle 
            title="งบประมาณและกำหนดการ"
            class="
              mt-20 
              bottom-line
            "
          />
          <div 
            class="input"
            :class="{ error: $v.form.budget.$error }"
          >
            <Label 
              text="งบประมาณ" 
              :required=true
            />

            <BudgetInput
              v-model="form.budget"
            />
          </div>
          <!-- end: Project budget -->

          <div 
            class="input"
            :class="{ error: $v.form.job_end.$error }"
          >
            <Label 
              text="กำหนดการแล้วเสร็จของโครงการ" 
              :required=true
            />

            <DateInput
              v-model="form.job_end"
            />
          </div>
          <!-- end: Job end -->

          <div 
            class="input"
            :class="{ error: $v.form.timeframe.$error }"
          >
            <Label 
              text="กำหนดการเริ่มดำเนินการโดยประมาณ" 
              :required=true
            />

            <TimeframeDropdown
              v-model="form.timeframe"
            />

            <!-- 
            TimeframeDetail display when 
            user choose 'Other' option (value=90)
            -->
            <input 
              v-model="form.timeframe_detail" 
              v-if="
                form.timeframe == 90
              "
              type="text" 
              class="input-newline"
              placeholder="กรุณาระบุ"
            >
          </div>
          <!-- end: Timeframe -->

          <ProjectSubmitBlock text="บันทึกข้อมูลโครงการ" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
// load library and variables
import {apiRequest} from '@/utils/axios/axiosInstance.js';
const {required, minLength} = require('vuelidate/lib/validators')

// Load components
import Label                from '@/components/common/form/Label.vue';
import SmallTitle           from '@/components/common/SmallTitle.vue';
import ProjectTypeDropdown  from '@/components/project/form/input/ProjectTypeDropdown.vue';
import BuildingTypeDropdown from '@/components/project/form/input/BuildingTypeDropdown.vue';
import BudgetInput          from '@/components/project/form/input/BudgetInput.vue';
import TimeframeDropdown    from '@/components/project/form/input/TimeframeDropdown.vue';
import DateInput            from '@/components/common/form/input/DateInput.vue';
import ProjectSubmitBlock   from '@/components/project/form/button/ProjectSubmitBlock.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  data() {
    return {
      form: {
        project_id: '',
        name:'',
        cat: '',
        building_type:'',
        building_detail: '',
        detail: '',
        budget: 0,
        timeframe: '',
        timeframe_detail: '',
        job_end: '',
      }
    }
  },

  /**
   * Validate form data before save to api
   */
  validations: {
    form: {
      name:           {required},
      cat:            {required},
      building_type:  {required},
      budget:         {
        required,
        minLength: minLength(3)
      },
      job_end:        {required},
      timeframe:      {required},
    },
    project: {}
  },

  /**
   * Registration components
   */
  components: {
    Label,
    SmallTitle,
    ProjectTypeDropdown,
    BuildingTypeDropdown,
    BudgetInput,
    TimeframeDropdown,
    DateInput,
    ProjectSubmitBlock
  },

  /**
   * Initial this component when created
   */
  created() {
    this.form.project_id = this.$route.params.id;
    
    // set page title
    this.$store.commit('setPageTitle', 'Edit Project');
    this.$store.commit('setHeadTitle', 'Edit Project : General');

    // get project data
    this.setForm();
  },

  /**
   * Methods
   */
  methods: {
    /**
     * Detect page loaded
     */
    isLoaded() {
      return !this.$store.state.show_loader;
    },

    /**
     * Get project data
     */
    setForm() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .get('/project/' + this.form.project_id)
        .then( (res) => {
          if (res.data.status != 200) {
            alert('การโหลดข้อมูลมีปัญหา กรุณาลองรีเฟรชหน้านี้ใหม่อีกครั้งหรือกด Back เพื่อกลับไปหน้าเดิมของท่าน !!!')
          }else{
            this.project = res.data.result;

            this.form.name = this.project.name;
            this.form.cat = this.project.cat;
            this.form.building_type = this.project.building_type;
            this.form.building_detail = this.project.building_detail;
            this.form.detail = this.project.detail;
            this.form.budget = this.project.budget;
            this.form.timeframe = this.project.timeframe;
            this.form.timeframe_detail = this.project.timeframe_detail;
            this.form.job_end = this.project.job_end;
            
            this.$store.commit('setLoaderShow', false);
          }
        });
    },

    /**
     * Save value to database via API
     */
    saveForm() {
      this.$v.$touch()
      
      // if not validate error
      // update data to database by API
      if (!this.$v.$invalid) {
        // show main loader
        this.$store.commit('setLoaderShow', true);
        
        apiRequest
          .patch(
            '/project/',
            this.form
          )
          .then( (res) => {
            if (res.data.status == 200) {
              this.$router.push('/project/view/' + this.form.project_id);
            } else {
              console.log(res.data);
              alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณารีเฟรชหน้านี้และลองใหม่อีกครั้ง !!!');
            }
          });
      }
    },
  }
}
</script>